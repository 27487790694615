import { reactive, ref } from "vue";

const rmaCreationState = ref([]);

export default function stepform() {
  const backToListRMA = () => {
    router.push("/listRma");
  };
  return {
    backToListRMA,
    rmaCreationState,
  };
}
