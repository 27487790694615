<template>
  <div class="resultpage">
    <!-- <a-result status="success" title="Submission Success"> -->
    <div class="ant-result ant-result-success">
      <a-row style="max-height: 80px">
        <a-col :span="6"></a-col>
        <a-col :span="12">
          <div class="ant-result-icon" style="display: flex;justify-content: center;">
            <span role="img" aria-label="check-circle" class="anticon anticon-check-circle">
              <svg 
                class=""
                data-icon="check-circle" 
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
                viewBox="64 64 896 896"
                focusable="false"
              >
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
              </svg>
            </span>
            <div class="ant-result-title" style="font-weight: 900;font-size: 22px;margin: 20px 0 0 20px;">
              {{ $t("route.SubmissionSuccess") }}
            </div>
          </div>
        </a-col>
        <a-col :span="6"></a-col>
      </a-row>
      <!-- <div class="ant-result-icon">
          <span role="img" aria-label="check-circle" class="anticon anticon-check-circle">
            <svg 
              class=""
              data-icon="check-circle" 
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
              viewBox="64 64 896 896"
              focusable="false"
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
            </svg>
          </span>
        </div>
        <div class="ant-result-title">
          Submission Success
        </div> -->
    </div>
    <!-- <template #extra>
 
        <a-button
          type="primary"
          shape="round"
          @click="backToListRMA"
          style="margin-right: 10px"
        >
  
          Return to RMA List  
        </a-button>
      </template> -->
    <!-- <div v-for="rmaItem in rmaResult" v-bind:key="rmaItem.data.id" class="content">
        <div> ID : {{ rmaItem.data.id }} </div>
        <div> Product Size : {{ rmaItem.data.product_size }} </div>
        <div> Product Sku : {{ rmaItem.data.product_sku }} </div>
        <div> Email : {{ rmaItem.data.email }}  </div>
        <div> Origin Invoice Number : {{ rmaItem.data.origin_invoice_number }} </div> 
        <div> Is Under Warranty : {{ rmaItem.data.is_under_warranty }} </div>
      </div>   -->
    <a-list size="large" :pagination="false" >
      <a-list-item :key="index" v-for="(item, index) in data">
        <a-row :gutter="24" style="width:100%">
          <a-col :sm="24" :md="12" :xl="6">
            <div class="list-content">
              <div class="list-content-item">
                <span>{{ $t("route.Item") }}</span>
                <p>{{ item.sku }}</p>
                <p>Size {{ item.size }}</p>
              </div>
              <div class="list-content-item">
                <a-image width="100px" size="large" shape="square" :src="item.avatar"/>
              </div>
            </div>
          </a-col>
          <a-col :sm="24" :md="12" :xl="4">
            <div class="list-content">
              <div class="list-content-item">
                <span>{{ $t("route.Contact") }}</span>
                <p>{{ item.firstname || item.lastname || '' }}</p>
                <p>{{ item.phone }}</p>
                <p>{{ item.email || '' }}</p>
              </div>
            </div>
          </a-col>
          <a-col :sm="24" :md="12" :xl="4">
            <div class="list-content">
              <div class="list-content-item">
                <span> {{ $t("route.Reasons") }}</span>
                <div v-for="reason in item.rma_item_to_reason" :key="reason.ID">
                  <p>{{ $t("reasonsName."+covertToCamelCase(reason.Name)) }}</p>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :sm="24" :md="12" :xl="4">
            <div class="list-content">
              <div class="list-content-item">
                <span> {{ $t("route.Attachment") }}</span>
                <div v-for="attachment in item.attachments" :key="attachment">
                  <p><a :href="attachment" target="_blank">link attachment</a></p>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :sm="24" :md="12" :xl="6">
            <div class="list-content comments">
              <div class="list-content-item">
                <span> {{ $t("route.StoreRemark") }}</span>
                <p>{{ item.comments }}</p>
              </div>
            </div>
          </a-col>
        </a-row>
        <!-- <a-list-item-meta :description="item.description">
            <template #title>
              <a>{{ item.title }}</a>
            </template>
            <template #avatar>
              <a-image width="100px" size="large" shape="square" :src="item.avatar"/>
            </template>
          </a-list-item-meta> -->
        <template #actions>
          <!-- <a-button type="primary" @click="handleRePrintLabel(item.id)">Print Label </a-button> -->
          <a :href="item.labelURL" target="_blank">{{ $t("listRMA.printLabel") }}</a>
          <!-- <a-button type="primary" @click="handleRePrintLabel(item.id)">Print Label </a-button>-->
        </template>
      </a-list-item>
    </a-list>
    <div style="text-align: center;">
      <a-button
        type="primary"
        shape="round"
        @click="backToListRMA"
        style="margin-right: 10px; text-align:center"
      >
           
        {{ $t("route.ReturnToRMAList") }}
      </a-button>
    </div>
    <!-- </a-result> -->
  </div>
</template>

<script>
  import router from '../../../router'
  import stepform from "@/composables/stepform"
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import { reactive } from "vue";

  
  export default {
    /* data() {
      return {
        data
      }
    }, */
    setup(){
      
      const { rmaCreationState, backToListRMA } = stepform()
      const rmaResult = rmaCreationState._value
      const { printItemLabel} = useRmaRepositories()
      const data = reactive([])
      //console.log("rmaResult", rmaCreationState.value.data,  "rmaCreationState.value", rmaCreationState._value)
      // console.log("rmaResult", rmaCreationState._value)
      // console.log(rmaResult.length)
      // data = []
      for (var i = 0; i < rmaCreationState._value.length; i++) { 

        data.push({
          id: rmaCreationState._value[i].data.id,
          sku: rmaCreationState._value[i].data.product_sku,
          avatar: 'https://imgcdn.apm-monaco.cn/products-images/'+  rmaCreationState._value[i].data.product_sku + '.jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76',
          size: rmaCreationState._value[i].data.product_size,
          phone: rmaCreationState._value[i].data.phone,
          email: rmaCreationState._value[i].data.email != null && rmaCreationState._value[i].data.email != 'null' ? rmaCreationState._value[i].data.email : '',
          firstname: rmaCreationState._value[i].data.firstname != null && rmaCreationState._value[i].data.firstname != 'null' ? rmaCreationState._value[i].data.firstname : '',
          firstname: rmaCreationState._value[i].data.lastname != null && rmaCreationState._value[i].data.lastname != 'null' ? rmaCreationState._value[i].data.lastname : '',
          attachments: rmaCreationState._value[i].attachmentUrl,
          rma_item_to_reason: rmaCreationState._value[i].data.rma_item_to_reason,
          comments: rmaCreationState._value[i].data.scomments
        })
        const idx = i 
        printItemLabel([rmaCreationState._value[i].data.id]).then((result)=>{
          const url = result.data.url
          data[idx].labelURL = url
        })
      }

      const onClickPrintLabel = () => {
        alert("print Label")
      }
      /*
        const result = await printItemLabel([id])
  
        const handleRePrintLabel = async (id) =>{ 
          const result = await printItemLabel([id])
          window.open(result.data.url, '_blank');
        }
      */

      const covertToCamelCase = (str) => { 
        return str.replace(/\s/g, "")
      } 
      return {
        covertToCamelCase,
        rmaResult,
        onClickPrintLabel,
        data
        // handleRePrintLabel
      }
    },
    methods: {
      backToListRMA() {
        router.push('/listRma')
      },
    }

  }
</script>

 

<style lang="scss" scoped>
.ant-list-item-action{
  margin-left:0px !important;
}
.ant-result-title {
  font-size: 18px;
}
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.item-comments{
  display:block !important;
}
.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  // vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
    color: #000000;
    font-size: 16px;
  }
  .ant-list-lg .ant-list-item {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    align-items: flex-start;
  }
  
}
 

.ant-result-success {
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
 
  
</style>
<style lang="scss">
@media only screen and (max-width: 1024px) {
  .resultpage{
    .ant-result-content{
      padding: 5px;
    }
    .ant-list-item-action{
      margin-left: 10px;
    }
  }
  .list-content-item:deep {
    color: rgba(0, 0, 0, 0.45);
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    margin-left: 20px;
  }
}
</style>

<style>
   .resultpage .ant-list-item {
    align-items: flex-start !important;
  }
</style>