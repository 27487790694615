<template>
  <div class="step-form-style-desc">
    <a-form style="max-width: 550px; margin: 40px auto 0">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="">
            <a-input v-model:value="invoiceNumber" @pressEnter="OnClickSearchInvoice" :placeholder="$t('route.InvoiceNo')" allow-clear style="width: 270px;"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :wrapperCol="{ span: 24, offset: 1 }">
            <a-button
              type="primary"
              @click="OnClickSearchInvoice"
              :loading="loadingSearchInvoice"
            >{{ $t('route.Search') }}</a-button>
            <a-button
              key="blankRMA"
              type="primary"
              @click="onClickCreateBlankRMA"
              style="margin-left: 20px;"
              v-if="showBlankRmaButton"
            >
              <template #icon>
                <PlusOutlined />
              </template>
              {{ $t('route.blankRMA') }}
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- ORDINT-1000031780 -->
    <a-modal
      v-model:visible="searchInvoiceModalvisible"
      centered
      width="100%"
      wrapClassName="full-modal"
      :maskClosable="false"
      :loading="loadingSearchInvoice"
    >
      
      <template #footer>
        <a-button key="back" @click="onClickCancel">{{ $t('permission.cancel') }}</a-button>
        <a-button key="submit" type="primary" @click="onClickSelectInvoice" :loading="loadingSearchInvoice">{{ $t('permission.submit') }}</a-button>
      </template>
      <a-layout>
        <a-layout-content>
          <a-table
            :data-source="searchInvoiceDataResult.invoices" 
            :columns="searchInvoiceModalColumns"
            :row-selection="invoicesRowSelection"
            :pagination="false"
            :customRow="customRow"
          >
            <template #name="{ text }">
              <a>{{ text }}</a>
            </template>
          </a-table>
        </a-layout-content>
      </a-layout>
    </a-modal> 

    <a-modal
      v-model:visible="searchInvoiceDetailsModalvisible"
      :title="invoiceNumber"
      :maskClosable="false"
      width="100%"
      wrapClassName="full-modal"
    >
      <template #footer>
        <p></p>
        <a-button key="back" @click="onClickCancel">{{ $t('permission.cancel') }}</a-button>
        <a-button
          key="submit"
          type="primary"
          @click="nextStepSearchByInvoice"
        >{{ $t('route.Search') }}</a-button
        >
      </template>
      <a-table
        :columns="searchInvoiceColumns"
        :data-source="searchInvoiceDataResult.product"
        :row-selection="searchInvoiceRowSelection"
        :pagination="false"
      >
        <template #img="{ record }">
          <div>
            <a-image width="50px" :src="record.avatar" :alt="record.sav" />
          </div>
        </template>
        <template #customRender="{ text, column }">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                class="highlight"
                :key="i"
              >
                {{ fragment }}
              </mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
  import { reactive, computed, ref } from '@vue/reactivity';
  import { notification } from 'ant-design-vue';
  import useClientRepositories from '@/composables/useClientRepositories';
  import { PlusOutlined } from '@ant-design/icons-vue';
  import { formatDate } from '@/utils/utils';
  import { useI18n } from 'vue-i18n';


  export default {
    data() {
      return {
        searchInvoiceRowSelection: {
          onSelect: (record, selected) => {
            if (selected) {
              record.selected = true
              this.searchInvoicerowSelected.push(record)
            } else {
              record.selected = false
              this.searchInvoicerowSelected = this.searchInvoicerowSelected.filter(function (ele) {
                return ele.itemno == selected.itemno
              })
            }
          },
          onSelectAll: (selected, selectedRows, changeRows) => {
            if (selectedRows) {
              this.searchInvoicerowSelected.push(selected)
            }
          },
        },
        searchInvoicerowSelected: [],
      }
    },
    emits: ['stepCreateBlankRMA', 'stepCreateRMA'],
    components: {
      PlusOutlined
    },
    setup() {
      const { searchInvoice, searchCustomerDetailsByCustomerGUID } = useClientRepositories()
      const searchInvoiceDataResult = reactive({customerInfo:{}, product:[], invoices:[]})
      const invoiceNumber = ref('')
      const searchInvoiceDetailsModalvisible = ref(false)
      const searchInvoiceModalvisible = ref(false)
      const loadingSearchInvoice = ref(false)
      const showBlankRmaButton = ref(false)
      const { t ,locale } = useI18n({ useScope: 'global' })
      const stateInvoice = reactive({
        selectedRowKeys: [],
        invoiceRowSelected: []
      });
      const searchInvoiceColumns = [
        {
          title: 'Image',
          key: 'img',
          slots: {
            customRender: 'img',
          },
        },
        {
          title: t('route.Date'),
          dataIndex: 'dateDisplay',
          key: 'dateDisplay',
        },
        {
          title: t('route.Warranty'),
          dataIndex: 'warranty',
          key: 'warranty',
        },
        {
          title: t('route.Item'),
          dataIndex: 'itemno',
          key: 'itemno',
        },
        {
          title: t('route.Size'),
          dataIndex: 'size',
          key: 'size',
        },
        {
          title: t('route.Amount'),
          dataIndex: 'amount',
          key: 'amount',
        },
      ]

      const searchInvoiceModalColumns = [
        {
          title: t('productDetail.orderNumber'),
          dataIndex: 'orderId',
          key: 'orderId',
          width: 110,
        },
        {
          title: t('route.OrderDate'),
          dataIndex: 'orderDate',
          key: 'orderDate',
          width: 170,
        },
        {
          title: t('route.Name'),
          dataIndex: 'fullname',
          key: 'fullname',
          width: 130,
        },
        {
          title: t('route.Email'),
          dataIndex: 'email',
          key: 'email',
          width: 130,
        },
        {
          title: t('route.Phone'),
          dataIndex: 'phone',
          key: 'phone',
          width: 130,
        },
      ]

      const invoicesRowSelection = computed(() => {
        return {
          selectedRowKeys: stateInvoice.selectedRowKeys,
          type:	'radio',
          onChange: (selectedRowKeys) => {
            stateInvoice.selectedRowKeys = selectedRowKeys;
          },
        };
      });

      const onClickSelectInvoice = () => {
        if (stateInvoice.selectedRowKeys.length <= 0) {
          notification.error({
            message: t('route.pleaseSelectAnInvoice'),
            description: ""
          })
          loadingSearchInvoice.value = false
        } else {
          searchInvoiceDataResult.customerInfo = searchInvoiceDataResult.customerInfo.filter(x => x.contactid = searchInvoiceDataResult.invoices[stateInvoice.selectedRowKeys].contactid)[0]
          // console.log('asdasd123456')
          // console.log(searchInvoiceDataResult)
          // console.log('987654321')
          // console.log(searchInvoiceDataResult.invoices[stateInvoice.selectedRowKeys].orderId)
          searchInvoiceDataResult.product = searchInvoiceDataResult.product.filter(x => x.apm_invoice_no == searchInvoiceDataResult.invoices[stateInvoice.selectedRowKeys].orderId)
          searchInvoiceModalvisible.value = false
          searchInvoiceDetailsModalvisible.value = true;
          
        }
      }

      const onClickCancel = () => {
        searchInvoiceDataResult.customerInfo = {}
        searchInvoiceDataResult.product = []
        searchInvoiceDetailsModalvisible.value = false
        searchInvoiceModalvisible.value = false
      }

      async function OnClickSearchInvoice() {
        loadingSearchInvoice.value = true
        searchInvoiceDataResult.customerInfo = []
        searchInvoiceDataResult.invoices = []
        searchInvoiceDataResult.product = []
        var tempResult = null
        showBlankRmaButton.value = false
        try {
          tempResult = await searchInvoice(invoiceNumber.value)
        }
        catch (e) {
          notification.error({
            message: t('route.NoMatchesFound'),
            description: ""
          })
          showBlankRmaButton.value = true
          loadingSearchInvoice.value = false
          return
        }

        var tempInvoiceNo = ""
        for(var i=0; i<tempResult.product.length; i++) {
          searchInvoiceDataResult.product.push(tempResult.product[i])
          if(tempResult.product[i].apm_invoice_no !== tempInvoiceNo) {
            tempInvoiceNo = tempResult.product[i].apm_invoice_no
            
            try {
              tempResult2 = await searchCustomerDetailsByCustomerGUID(tempResult.product[i]._customerid_value)
            }
            catch (e) {
              console.log(e)
              notification.error({
                message: 'No Products found',
                description: ""
              })
              loadingSearchInvoice.value = false
              return
            }
            var inv = { 
              contactid: tempResult2.customerInfo.data.contactid,
              orderId: tempResult.product[i].apm_invoice_no,
              orderDate: formatDate(tempResult.product[i].date),
              fullname: tempResult2.customerInfo.data.fullname,
              email: tempResult2.customerInfo.data.emailaddress1,
              phone: "(" + tempResult2.customerInfo.data.new_apm_phoneareacode + ") " + tempResult2.customerInfo.data.mobilephone,
            }
            searchInvoiceDataResult.invoices.push(inv)
            searchInvoiceDataResult.customerInfo.push(tempResult2.customerInfo)
          }
        }
        var tempResult2 = null 

        searchInvoiceModalvisible.value = true

        loadingSearchInvoice.value = false

      }
      // ORD2INT-1000656784-EN
      return { searchInvoiceDataResult, OnClickSearchInvoice, invoiceNumber, searchInvoiceDetailsModalvisible, searchInvoiceModalvisible, 
               loadingSearchInvoice, onClickCancel, invoicesRowSelection, onClickSelectInvoice, searchInvoiceColumns, searchInvoiceModalColumns, showBlankRmaButton }
    },
    methods: {
      onClickCreateBlankRMA() {
        this.$emit('stepCreateBlankRMA', 1)
      },
      nextStepSearchByInvoice() {
        if (this.searchInvoicerowSelected.length > 0) {
          this.$emit("stepCreateRMA", this.searchInvoiceDataResult);
          this.searchInvoiceDetailsModalvisible = !this.searchInvoiceDetailsModalvisible
        } else {
          notification.error({
            message: t('route.pleaseSelectAProduct'),
            description: ""
          })
        }
      },
    }
  }
</script>
<style lang="scss" scoped>


.ant-table-pagination.ant-pagination {
  float: right;
  margin: 8px 0;
}
</style>