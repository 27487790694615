<template>
  <div>
    <a-card :bordered="true">
      <div class="step-form-style-desc">
        <p><b style="font-size: 16px;">{{ $t('route.SearchingWithName') }}</b></p>
      </div>

      <!-- Component Search Customer -->
      <search-customer @stepCreateRMA="stepCreateRMA" @stepCreateBlankRMA="stepCreateBlankRMA" />

      <a-divider />

      <div class="step-form-style-desc">
        <p><b style="font-size: 16px;">{{ $t('route.SearchingWithTheInvoiceNumber') }}</b></p>
      </div>

      <!-- Component Search Invoice -->
      <SearchInvoice @stepCreateRMA="stepCreateRMA" @stepCreateBlankRMA="stepCreateBlankRMA" />
    </a-card>
    <!-- <a-button key="blankRMA" type="primary" @click="onClickCreateBlankRMA">
      <template #icon>
        <PlusOutlined />
      </template>
      {{ $t('route.blankRMA') }}
    </a-button> -->
  </div>
</template>

<script>
  import { computed, reactive, ref } from '@vue/reactivity'
  import { message } from 'ant-design-vue'
  import { notification } from 'ant-design-vue';
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import SearchInvoice from '@/components/Client/SearchInvoice.vue'
  import SearchCustomer from '@/components/Client/SearchCustomer.vue'
  import stepform from "@/composables/stepform"
  import { PlusOutlined } from '@ant-design/icons-vue';

  export default {
    name: 'StepOne',
    components: {
      SearchInvoice,
      SearchCustomer,
      PlusOutlined
    },
    emits: ['nextStep'],
    data () {
      return {
        searchInvoiceRowSelection: {
          onSelect: (record, selected) => {
            if (selected) {
              record.selected = true
              this.searchInvoicerowSelected.push(record)
            } else {
              record.selected = false
              this.searchInvoicerowSelected = this.searchInvoicerowSelected.filter(function (ele) {
                return ele.itemno == selected.itemno
              })
            }
          },
          onSelectAll: (selected, selectedRows, changeRows) => {
            if (selectedRows) {
              this.searchInvoicerowSelected.push(selected)
            } else {
              // console.log(selected, selectedRows, changeRows)
            }
          // console.log(selected, selectedRows, changeRows);
          },
        },
        searchInvoicerowSelected: [],
      }
    },
    setup() {

      const { rmaCreationState } = stepform()
      rmaCreationState._value = []
      var searchOrderDetailsModal2Visible = ref(false)
      var key = ref(0)
      const customers = reactive({
        array: []
      })
      const orderDetails = reactive({
        array: []
      })

      var informationNextStep = reactive({
        customerInfo: {},
        product: []
      })

      return { searchOrderDetailsModal2Visible, customers, orderDetails, informationNextStep}

    },
    methods: {
      stepCreateRMA(searchResult) {
        for(var i = 0; i < searchResult.product.length; i++) {
          this.informationNextStep.product.push(searchResult.product[i])
        }
        this.informationNextStep.customerInfo = searchResult.customerInfo

        this.$emit("nextStep", 1, this.informationNextStep);

      },
      stepCreateBlankRMA() {
        this.$emit('nextStep', 1, null)
      },
      onClickCreateBlankRMA() {
        this.$emit('nextStep', 1, null)
      }
    }
  }
</script>

<style lang="scss" scoped>
.step-form-style-desc {
  padding: 0 56px;
  color: rgba(0,0,0,.45);
  h3 {
    margin: 0 0 12px;
    color: rgba(0,0,0,.45);
    font-size: 16px;
    line-height: 32px;
  }
  h4 {
    margin: 0 0 4px;
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 22px;
  }
  p {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 22px;
  }
}

.ant-btn-primary {
  color: #fff;
  background-color: #001628;
  border-color: #001628;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
</style>