<template>
  <!-- <page-header-wrapper> -->
  <!-- PageHeader 第二种使用方式 (v-slot) -->
  <div>
    <h1 style="padding:24px;">{{ $t('route.CreateRMAForm') }}</h1>

    <a-card :bordered="false">
      <a-steps progress-dot class="steps" :current="currentTab">
        <a-step :title="$t('route.Searching')" />
        <a-step :title="$t('route.CreateRMAForm')" />
        <a-step :title="$t('route.Completed')" />
      </a-steps>
      <div class="content">
        <step-one v-if="currentTab === 0" @nextStep="nextStep"/>
        <step-create-rma v-if="currentTab === 1" @nextStep="nextStep" @prevStep="prevStep" :results="resultSearch"/>
        <step-completed v-if="currentTab === 2" @nextStep="nextStep" @prevStep="prevStep" :results="resultSearch"/>
      </div>
    </a-card>
  </div>
  <!-- </page-header-wrapper> -->

</template>

<script>
  import StepOne from "./stepOne";
  import StepCreateRma from "./stepCreateRMA"
  import StepCompleted from "./stepCompleted"
  import { useI18n } from 'vue-i18n'

  export default {
    components: {
      StepOne,
      StepCreateRma,
      StepCompleted
    },
    setup() {
      const { t } = useI18n({ useScope: 'global' })

      return { t }
    },
    data () {
      return {
        resultSearch: null,
        currentTab: 0,
        form: null,
        isSearchByCustomer: false,
        isSearchByInvoice: false,
        searchLabel : 'Search'
      }
    },
    methods: {
      // handler
      nextStep (chosenStep, results) {
        if (this.currentTab < 3) {
          if (chosenStep === 0) {
            this.currentTab = 0
          } else if (chosenStep === 1) {
            this.currentTab = 1
            this.resultSearch = results
          } else if (chosenStep === 2) {
            this.currentTab = 2
          }
        } else {
          this.currentTab = 4
        }
      },
      searchStep() {
        this.currentTab = 1
      },
      createRMAStep() {
        this.currentTab = 2
      },
      prevStep () {
        if (this.currentTab === 1) {
          this.searchLabel = 'Search'
          this.currentTab -= 1
        }
        else if (this.currentTab > 1) {
          this.currentTab -= 1
        }
      },
      finish () {
        this.currentTab = 0
      },
      completeStep(payload) {
        this.demoSteps.forEach((step) => {
          if (step.name === payload.name) {
            step.completed = true
          }
        })
      },
      // Executed when @active-step event is triggered
      isStepActive(payload) {
        this.demoSteps.forEach((step) => {
          if (step.name === payload.name) {
            if(step.completed === true) {
              step.completed = false
            }
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
.steps {
  max-width: 850px;
  margin: 16px auto;
}
.ant-btn-primary {
  color: #fff;
  background-color: #001628;
  border-color: #001628;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #001628!important;
  border-color: #001628!important;
  color: #fff;
}
</style>